angular.module("mfgApp")
    .factory('addBulkSerialNumbers', ["$uibModal", function ($uibModal) {
        function open(serialNumber) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/mfg/addBulkSerialNumbersModal.html",
                controller: "AddBulkSerialNumbersModalCtrl",
                resolve: {
                    serialNumber: function () { return serialNumber; }
                }
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("AddBulkSerialNumbersModalCtrl", ["$scope", "$uibModalInstance", "serialNumber", function ($scope, $uibModalInstance, serialNumber) {
        $scope.existingSerialNumber = serialNumber;
        $scope.bulkNumber = 0;
        var addedSerials = [];

        $scope.addBulkSerialNumbers = function () {
            var serialNumber = $scope.existingSerialNumber;
            var serialKey = parseInt(serialNumber.match(/\d+$/));
            var pos = serialNumber.indexOf(serialKey);
            var str = serialNumber.slice(0,pos);

            for (var i=1; i<=$scope.bulkNumber; i++){
                serialKey++;
                serialNumber = str + serialKey;
                var newSerialNumber = {};
                newSerialNumber.serialNumber = serialNumber;
                newSerialNumber.createdOn = moment().format('L');
                newSerialNumber['new'] = true;
                addedSerials.push(newSerialNumber);
            }
            $uibModalInstance.close(addedSerials);
        };
    }]);